import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';

import {feedbackTypes} from 'utils/constants/constants';
import {isValidEmail} from 'utils/helpers/validators';
import {SendStatus} from './SendStatusEnum';

import {clearAttachAction} from 'redux/feedback/actions';
import {joinNewsletterAction, sendFeedbackAction} from 'redux/feedback/feedbackAsyncActions';
import {IStore} from 'redux/interface';
import {sendFeedback} from 'redux/pageAsyncActions';

import ModalWindow from 'components/modal-window';
import Loader from 'components/loader';
import {ButtonVariant, GritxButton} from '@wholesalechange/chatcomponent';

import IssueForm from './IssueForm';
import {IIssueFormModel} from './interfaces';

import FinishImg from 'assets/image/feedback/skill-how-to-lemonade.svg';

import './style.scss';

interface IReportAnIssue {
  type: string
  onClose: () => void
}

interface IFormTitles {
  [key: string]: {
    [key: string]: {
      id: string
      defaultMessage: string
    }
  }
}

const formTitles: IFormTitles = {
  issue: {
    title: {
      id: 'gritx.feedback.issueForm.issue.label',
      defaultMessage: 'Report an Issue'
    },
    textBox: {
      id: 'gritx.feedback.issueForm.textBox.label',
      defaultMessage: 'Issue'
    }
  },
  newContent: {
    title: {
      id: 'gritx.feedback.issueForm.newContent.label',
      defaultMessage: 'Content Request'
    },
    textBox: {
      id: 'gritx.feedback.issueForm.newContent.textBox.label',
      defaultMessage: 'Description'
    }
  },
  joinUCSF: {
    title: {
      id: 'gritx.feedback.issueForm.joinUCSF.label',
      defaultMessage: 'Partnership Request'
    },
    textBox: {
      id: 'gritx.feedback.issueForm.joinUCSF.textBox.label',
      defaultMessage: 'Description'
    }
  },
  shareHistory: {
    title: {
      id: 'gritx.feedback.issueForm.shareHistory.label',
      defaultMessage: 'Share GritX Story'
    },
    textBox: {
      id: 'gritx.feedback.issueForm.shareHistory.textBox.label',
      defaultMessage: 'Description'
    }
  },
  chat: {
    title: {
      id: 'gritx.feedback.issueForm.chat.label',
      defaultMessage: 'Message the Brainy team'
    },
    textBox: {
      id: 'gritx.feedback.issueForm.chat.textBox.label',
      defaultMessage: 'Description'
    }
  }
};

export default function FeedbackForms({
  type,
  onClose
}: IReportAnIssue): JSX.Element {
  const [isShow, setIsShow] = useState(false);
  const [sendStatus, setSendStatus] = useState(SendStatus.Draft);
  const [formData, setFormData] = useState<IIssueFormModel>({
    email: '',
    issue: '',
    files: []
  });
  const {
    feedback,
    status: {loading}
  } = useSelector((state: IStore) => state);

  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    setIsShow(Object.keys(feedbackTypes)
      .includes(type));
  }, [type]);

  function handleClose() {
    dispatch(clearAttachAction());
    setFormData({
      email: '',
      issue: '',
      files: []
    });
    setSendStatus(SendStatus.Draft);
    onClose();
  }

  function handleChangeFormData(data: IIssueFormModel) {
    setFormData(data);
  }

  function handleSend() {
    const filesIds = formData.files.map(item => item.key);
    let fileKeys = '';

    if (Object.keys(feedback).length) {
      fileKeys = filesIds.map((item) => {
        return feedback[item.toString()].fileId;
      })
        .join(',');
    }
    setSendStatus(SendStatus.Sending);
    if (type !== feedbackTypes.newsletter) {
      dispatch(sendFeedbackAction({
        from: formData.email,
        subject: type,
        text: formData.issue,
        attachments: fileKeys
      }, () => {
        setSendStatus(SendStatus.Finish);
      }));
    } else {
      dispatch(joinNewsletterAction({
        to: formData.email,
        subject: type,
        text: formData.issue,
        attachments: fileKeys
      }, () => {
        setSendStatus(SendStatus.FinishNewsletter);
      }));
    }
  }

  function getDisabledStatus(): boolean {
    const filesIds = formData.files.map(item => item.key);
    const loadingStatus = filesIds.reduce((acc, item) => {
      return loading.includes(item.toString());
    }, false);

    if (
      type !== feedbackTypes.newsletter
      && (formData.email.length === 0
      || !isValidEmail(formData.email)
      || formData.issue.trim().length < 3
      || loadingStatus)
    ) {
      return true;
    }

    return type === feedbackTypes.newsletter
      && (formData.email.length === 0
        || !isValidEmail(formData.email));
  }

  const formsButtons = () => {
    return sendStatus === SendStatus.Draft
      ? <div className="feedback-form__footer">
        <GritxButton
          title={intl.formatMessage({
            id: 'gritx.common.button.cancel',
            defaultMessage: 'Cancel'
          })}
          variant={ButtonVariant.Light}
          className="feedback-form__button"
          onClick={handleClose}
        />
        <GritxButton
          title={intl.formatMessage({
            id: 'gritx.common.button.send',
            defaultMessage: 'Send'
          })}
          variant={ButtonVariant.Primary}
          className="feedback-form__button"
          onClick={handleSend}
          disabled={getDisabledStatus()}
        />
      </div>
      : null;
  };

  const finishMessage = () => {
    if (sendStatus === SendStatus.Finish || sendStatus === SendStatus.FinishNewsletter) {
      return <div className={'feedback-form__container'}>
        <h2 className={'feedback-form__title'}>
          {sendStatus === SendStatus.Finish ? <FormattedMessage
            id={'gritx.feedback.message-sent'}
            defaultMessage={'Your message was sent'}
          /> : <FormattedMessage
            id={'gritx.feedback.newsletter-message-sent'}
            defaultMessage={'Your message was sent'}
          />
          }

        </h2>
        <div className={'feedback-form__image-wrapper'}>
          <img
            className={'feedback-form__image'}
            src={FinishImg}
            alt=""
          />
        </div>
      </div>;
    }

    return null;
  };

  const content = () => {
    if (sendStatus === SendStatus.Draft) {
      return <div className={'feedback-form__container'}>
        {
          type && type !== feedbackTypes.newsletter
          && <IssueForm
            onChange={handleChangeFormData}
            title={intl.formatMessage(formTitles[type].title)}
            textBoxLabel={intl.formatMessage(formTitles[type].textBox)}
            showAttachments
            showTextBox
          />
        }
        {
          type === feedbackTypes.newsletter
          && <IssueForm
            onChange={handleChangeFormData}
            title={intl.formatMessage({
              id: 'gritx.feedback.issueForm.newsletter.label',
              defaultMessage: 'Join Newsletter'
            })}
          />
        }
      </div>;
    }
    if (sendStatus === SendStatus.Sending && loading.includes(sendFeedback)) {
      return <Loader nested/>;
    }

    return finishMessage();
  };

  return <ModalWindow
    show={isShow}
    onHide={handleClose}
    className={'feedback-form__modal'}
    footerElement={formsButtons()}
  >
    {content()}
  </ModalWindow>;
}
